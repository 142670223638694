'use client'

import {useEffect, useMemo, useState} from 'react'

import splitIntoGroups from '@/utils/split-into-groups'
import {useWindowSize} from '@/utils/use-window-size' // Import useWindowSize for dynamic resizing
import {ProductCardFragment} from '@atorie/api/vision'
import {VirtualList} from '@atorie/ui/lists'

import {ProductItem as ShopifyProductItem} from '../shopify/shopify-product-item'
import ProductModal from './shopify-product-modal'

const OVERSCAN = 3

interface ShopifyProductListProps {
  title?: string | null
  products: ProductCardFragment[]
  imageIndices?: number[]
  variantIds?: string[]
  category?: string
  renderEnd?: () => React.ReactNode
}

interface ProductWithImageIndex {
  productId: string
  imageIndex: number
}

export function ShopifyProductList({
  title,
  products,
  imageIndices,
  variantIds,
  category,
  renderEnd,
}: ShopifyProductListProps) {
  const [selectedProduct, setSelectedProduct] =
    useState<ProductWithImageIndex | null>(null)

  const [groupSize, setGroupSize] = useState(2) // State to hold dynamic group size
  const [width] = useWindowSize() // Hook to get the current window width

  // Update group size based on window width (same logic as File A)
  useEffect(() => {
    if (width && width >= 1536) {
      setGroupSize(4)
    } else if (width && width >= 1280) {
      setGroupSize(4)
    } else if (width && width >= 640) {
      setGroupSize(3)
    } else {
      setGroupSize(2)
    }
  }, [width])

  const productsRows = useMemo(() => {
    // Determine how many nulls to add for padding
    const paddingNeeded = groupSize - (products.length % groupSize)
    const paddedProducts =
      paddingNeeded < groupSize
        ? [...products, ...Array(paddingNeeded).fill(null)]
        : products

    return splitIntoGroups(paddedProducts, groupSize)
  }, [products, groupSize])

  return (
    <div className="size-full">
      <ProductModal
        isOpen={selectedProduct !== null}
        productId={selectedProduct?.productId}
        imageIndex={selectedProduct?.imageIndex}
        onClickOutside={() => setSelectedProduct(null)}
      />
      <VirtualList
        className="px-2 pt-8"
        count={productsRows.length}
        overscan={OVERSCAN}
        items={productsRows}
        renderEnd={renderEnd}
        renderItem={(row, rowIndex) => {
          if (!row) {
            return null
          }
          return (
            <div
              key={rowIndex}
              className="fadein-150 flex w-full justify-center gap-6 pb-6 sm:gap-8 sm:pb-8 lg:gap-10 lg:pb-10"
            >
              {row.map((product, i) => (
                <button
                  key={
                    product
                      ? product?.id +
                        (variantIds?.[rowIndex * groupSize + i] ?? '')
                      : Math.random()
                  }
                  className="relative w-40 xl:w-60"
                  onClick={() =>
                    setSelectedProduct({
                      productId: product?.id,
                      imageIndex: imageIndices?.[rowIndex * groupSize + i] ?? 0,
                    })
                  }
                >
                  {product && (
                    <ShopifyProductItem
                      key={product.id}
                      productId={product.id}
                      imageIndex={imageIndices?.[rowIndex * groupSize + i]}
                      className="w-full shadow-[0px_0px_4px_2px_rgba(0,0,0,0.1)]"
                    />
                  )}

                  {i === 0 && rowIndex === 0 && (
                    <div className="avenir absolute -top-9 w-80 self-start text-start text-xl">
                      {title}
                    </div>
                  )}
                </button>
              ))}
            </div>
          )
        }}
      />
    </div>
  )
}
