import {useEffect, useState} from 'react'

type WindowSize = {
  width: number | undefined
  height: number | undefined
}

export function useWindowSize(): [number | undefined, number | undefined] {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: undefined,
    height: undefined,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize() // Call once on mount to set initial size

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return [windowSize.width, windowSize.height]
}
